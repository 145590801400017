"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.points = exports.singleLine = void 0;
const proj_1 = require("ol/proj");
function singleLine(points) {
    return {
        "type": "FeatureCollection",
        "features": [
            {
                'type': 'Feature',
                'geometry': {
                    'type': 'LineString',
                    'coordinates': points.map(point => proj_1.fromLonLat(point))
                }
            }
        ]
    };
}
exports.singleLine = singleLine;
function points(points) {
    return {
        "type": "FeatureCollection",
        "features": points.map((point, i) => ({
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': proj_1.fromLonLat([point.long, point.lat])
            },
            'properties': {
                'index': i
            }
        }))
    };
}
exports.points = points;
