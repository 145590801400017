"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepare = void 0;
const lap_1 = require("../../models/lap");
const distance_1 = require("../distance");
function prepare(activity) {
    return {
        sport: activity.sport,
        laps: activity.laps.map(lap => prepareLap(lap))
    };
}
exports.prepare = prepare;
function prepareLap(lap) {
    const totals = lap_1.aggregateTotals(lap.trackpoints);
    return {
        time: totals.time,
        distance: totals.distance,
        maxSpeed: totals.maxSpeed,
        startTime: lap.trackpoints[0].time,
        calories: lap.totals.calories,
        trackpoints: prepareTrackpoints(lap.trackpoints)
    };
}
function prepareTrackpoints(trackpoints) {
    const prepared = trackpoints.map((trackpoint, index) => ({
        time: trackpoint.time,
        lat: trackpoint.lat,
        long: trackpoint.long,
        altitude: trackpoint.altitude,
        distance: index == 0 ? 0 : distance_1.distanceBetween([trackpoint.long, trackpoint.lat], [trackpoints[index - 1].long, trackpoints[index - 1].lat])
    }));
    // Distance must be cumulative
    let cumulativeDistance = 0;
    for (let i = 0; i < prepared.length; i++) {
        cumulativeDistance = cumulativeDistance + prepared[i].distance;
        prepared[i].distance = cumulativeDistance;
    }
    return prepared;
}
