"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceAt = exports.lastOf = void 0;
function lastOf(arr) {
    if (arr.length == 0) {
        return null;
    }
    return arr[arr.length - 1];
}
exports.lastOf = lastOf;
function replaceAt(arr, index, value) {
    const copy = arr.slice(0);
    copy[index] = value;
    return copy;
}
exports.replaceAt = replaceAt;
