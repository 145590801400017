"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const waitReducer = (state = 0, action) => {
    switch (action.type) {
        case 'START_WAITING':
            return state + 1;
        case 'STOP_WAITING':
            return state - 1;
        default:
            return state;
    }
};
exports.default = waitReducer;
