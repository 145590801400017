"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TCXDocument = void 0;
const tcx_node_1 = require("./tcx-node");
const OPERATIONS = {
    int: (value) => (parseInt(value, 10)),
    float: (value) => (parseFloat(value))
};
const CONVERSIONS = {
    "TotalTimeSeconds": OPERATIONS.int,
    "DistanceMeters": OPERATIONS.int,
    "MaximumSpeed": OPERATIONS.float,
    "Calories": OPERATIONS.float
};
class TCXDocument {
    constructor(source) {
        this.source = source;
        this.xmldoc = new DOMParser().parseFromString(source, "text/xml");
        const activityElement = this.xmldoc.getElementsByTagName("Activity")[0];
        this.activityNode = new tcx_node_1.TCXNode(activityElement);
        this.sport = activityElement.getAttribute('Sport') || '';
    }
    get laps() {
        const lapNodes = this.activityNode.children('Lap');
        return lapNodes.map(lapNode => ({
            trackpoints: this.trackpointsOf(lapNode),
            totals: this.lapTotals(lapNode)
        }));
    }
    get trackpoints() {
        return this.laps[0].trackpoints;
    }
    get totalTime() {
        return parseInt(this.activityNode.childContent('TotalTimeSeconds'), 10);
    }
    lapTotals(lap) {
        return {
            time: parseFloat(lap.childContent("TotalTimeSeconds")),
            distance: parseFloat(lap.childContent("DistanceMeters")),
            maxSpeed: parseFloat(lap.childContent("MaximumSpeed")),
            calories: parseFloat(lap.childContent("Calories", '0'))
        };
    }
    trackpointsOf(lap) {
        const trackNode = lap.firstChild("Track");
        const allTrackpointNodes = trackNode.children("Trackpoint");
        const trackpointNodes = allTrackpointNodes.filter(node => (node.hasChild("Position") && node.hasChild("Time")));
        return trackpointNodes.map(trackpoint => {
            const positionNode = trackpoint.firstChild("Position");
            return {
                time: trackpoint.childContent("Time"),
                lat: parseFloat(positionNode.childContent("LatitudeDegrees")),
                long: parseFloat(positionNode.childContent("LongitudeDegrees")),
                altitude: parseFloat(trackpoint.childContent("AltitudeMeters", '0'))
            };
        });
    }
}
exports.TCXDocument = TCXDocument;
