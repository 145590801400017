"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const activity_1 = require("../../models/activity");
const activityReducer = (state, action) => {
    switch (action.type) {
        case 'SAVE_PROCESSED_ACTIVITY':
            return { original: action.original, processed: action.processed };
        default:
            return state || { original: '', processed: activity_1.Activity.empty() };
    }
};
exports.default = activityReducer;
