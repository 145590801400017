"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_dropzone_1 = require("react-dropzone");
const flash_messages_1 = require("../state/helpers/flash-messages");
const waits_1 = require("../state/helpers/waits");
require("./Dropzone.css");
function Dropzone(props) {
    const onDrop = react_1.useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            assertSize(file);
            const reader = new FileReader();
            reader.onabort = () => {
                waits_1.stopWaiting();
                console.warn('file reading was aborted');
            };
            reader.onerror = () => {
                waits_1.stopWaiting();
                console.error('file reading has failed');
            };
            reader.onload = () => {
                const str = reader.result;
                waits_1.stopWaiting();
                props.onFileRead(str);
            };
            waits_1.startWaiting();
            reader.readAsText(file);
        });
    }, []);
    const { getRootProps, getInputProps } = react_dropzone_1.useDropzone({ onDrop: onDrop, accept: '.tcx,.xml' });
    return (react_1.default.createElement("div", Object.assign({ className: 'dropzone' }, getRootProps()),
        react_1.default.createElement("input", Object.assign({}, getInputProps())),
        react_1.default.createElement("p", null, "Drag 'n' drop tcx track here, or click to select files"),
        react_1.default.createElement("p", { className: 'import-invitation' },
            "or import a workout from ",
            react_1.default.createElement("a", { onClick: props.toggleSource }, "strava"))));
}
const MEGABYTE = Math.pow(2, 20);
const SIZE_LIMIT = 10 * MEGABYTE;
function assertSize(file) {
    if (file.size > SIZE_LIMIT) {
        flash_messages_1.notify(`This file is ${Math.floor(file.size / MEGABYTE)} MB. That's a bit too large for a tcx track, sorry`);
        throw new Error('File size too large');
    }
}
exports.default = Dropzone;
