"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxSpeed = exports.totalDistace = exports.distanceBetween = void 0;
const sphere_1 = require("ol/sphere");
const date_helpers_1 = require("./date-helpers");
// TODO: delete?
const distanceBetween = (from, to) => (sphere_1.getDistance(from, to));
exports.distanceBetween = distanceBetween;
const totalDistace = (trackpoints) => {
    if (trackpoints.length == 0) {
        return 0;
    }
    const total = trackpoints.reduce((total, current, currentIndex) => {
        if (currentIndex == 0) {
            return 0;
        }
        else {
            const previous = trackpoints[currentIndex - 1];
            return total + distanceBetween([previous.long, previous.lat], [current.long, current.lat]);
        }
    }, 0);
    return total;
};
exports.totalDistace = totalDistace;
const maxSpeed = (trackpoints) => {
    if (trackpoints.length == 0) {
        return 0;
    }
    const maxSpeed = trackpoints.reduce((maxSpeed, current, currentIndex) => {
        if (currentIndex == 0) {
            return 0;
        }
        else {
            const previous = trackpoints[currentIndex - 1];
            const distance = distanceBetween([previous.long, previous.lat], [current.long, current.lat]);
            const time = date_helpers_1.differenceSeconds(new Date(previous.time), new Date(current.time));
            const speed = (distance / time);
            return Math.max(maxSpeed, speed);
        }
    }, 0);
    return maxSpeed;
};
exports.maxSpeed = maxSpeed;
