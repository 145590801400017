"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interactions = void 0;
const interaction_1 = require("ol/interaction");
const proj_1 = require("ol/proj");
const helpers_1 = require("./helpers");
const forbidden = () => (false);
const interactions = (features, { onSelected, onDeSelected, onMoved }) => {
    const mainFeature = features.item(0);
    const line = mainFeature.getGeometry();
    const select = new interaction_1.Select({});
    // const select = new Select({condition: pointerMove});
    select.on('select', e => {
        if (e.selected.length > 0) {
            const selected = line.getClosestPoint(e.mapBrowserEvent.coordinate);
            const selectedIndex = line.getCoordinates().findIndex(c => helpers_1.isSame(c, selected));
            if (selectedIndex > -1) {
                onSelected(selectedIndex);
            }
        }
    });
    const modify = new interaction_1.Modify({
        features: features,
        deleteCondition: forbidden,
        insertVertexCondition: forbidden
    });
    modify.on('modifyend', e => {
        const feature = e.features.getArray()[0];
        if (feature == null) {
            return;
        }
        const geometry = feature.getGeometry();
        const newCoordinates = geometry.getCoordinates().map(coordinatePair => proj_1.toLonLat(coordinatePair));
        onMoved(newCoordinates);
    });
    const snap = new interaction_1.Snap({ features: features, edge: false });
    // Snap should be LAST as it affects all the interactions that follow
    return [select, modify, snap];
};
exports.interactions = interactions;
