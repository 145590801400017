"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decode = void 0;
const activity_1 = require("../../models/activity");
const waits_1 = require("../../state/helpers/waits");
const tcx_document_1 = require("./tcx-document");
function decode(source) {
    try {
        const tcx = waits_1.withWait(() => new tcx_document_1.TCXDocument(source));
        return new activity_1.Activity(tcx.sport, tcx.laps, source);
    }
    catch (e) {
        console.error(`Could not parse tcx file . ${e.message}`);
        return null;
    }
}
exports.decode = decode;
