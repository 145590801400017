"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISCARD_MESSAGES = exports.SHOW_MESSAGE = void 0;
const SHOW_MESSAGE = (text) => ({
    type: 'SHOW_MESSAGE',
    text: text
});
exports.SHOW_MESSAGE = SHOW_MESSAGE;
const DISCARD_MESSAGES = { type: 'DISCARD_MESSAGES' };
exports.DISCARD_MESSAGES = DISCARD_MESSAGES;
