"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../types");
const sourceReducer = (state = types_1.Source.file, action) => {
    switch (action.type) {
        case 'SET_SOURCE':
            return action.source;
        default:
            return state;
    }
};
exports.default = sourceReducer;
