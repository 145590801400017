"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSame = exports.fit = void 0;
const defaultPadding = [25, 25, 25, 25];
const fit = (map, layer) => {
    map.getView().fit(layer.getSource().getExtent(), { padding: defaultPadding });
};
exports.fit = fit;
const isSame = (coordinates1, coordinates2) => {
    return coordinates1[0] == coordinates2[0] && coordinates1[1] == coordinates2[1];
};
exports.isSame = isSame;
