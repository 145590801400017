"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTrackpoint = void 0;
function buildTrackpoint(original, coordinates) {
    return {
        time: original.time,
        long: coordinates[0],
        lat: coordinates[1],
        altitude: original.altitude
    };
}
exports.buildTrackpoint = buildTrackpoint;
