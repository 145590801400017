"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../types");
const stageProgression = [types_1.Stage.start, types_1.Stage.show, types_1.Stage.export];
function nextStage(stage) {
    const currentIndex = stageProgression.findIndex(progression => progression == stage);
    if (currentIndex == stageProgression.length - 1) {
        return stage;
    }
    else {
        return stageProgression[currentIndex + 1];
    }
}
function previousStage(stage) {
    const currentIndex = stageProgression.findIndex(progression => progression == stage);
    if (currentIndex == 0) {
        return stage;
    }
    else {
        return stageProgression[currentIndex - 1];
    }
}
const stageReducer = (state = types_1.Stage.start, action) => {
    switch (action.type) {
        case 'NEXT_STAGE':
            return nextStage(state);
        case 'PREVIOUS_STAGE':
            return previousStage(state);
        default:
            return state;
    }
};
exports.default = stageReducer;
