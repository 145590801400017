"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addSeconds = exports.differenceSeconds = void 0;
function differenceSeconds(start, finish) {
    const startTime = start.getTime();
    const finishTime = finish.getTime();
    const diff = finishTime - startTime;
    return Math.abs(diff / 1000);
}
exports.differenceSeconds = differenceSeconds;
function addSeconds(date, seconds) {
    return new Date(date.getTime() + seconds * 1000);
}
exports.addSeconds = addSeconds;
