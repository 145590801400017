"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aggregateTotals = void 0;
const array_1 = require("../lib/array");
const date_helpers_1 = require("../lib/date-helpers");
const distance_1 = require("../lib/distance");
function aggregateTotals(trackpoints, extras = {}) {
    return Object.assign({ time: date_helpers_1.differenceSeconds(new Date(array_1.lastOf(trackpoints).time), new Date(trackpoints[0].time)), distance: distance_1.totalDistace(trackpoints), maxSpeed: distance_1.maxSpeed(trackpoints) }, extras);
}
exports.aggregateTotals = aggregateTotals;
