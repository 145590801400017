"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const prefix = 'https://www.strava.com/oauth/authorize';
const queryParams = {
    scope: encodeURI("activity:read"),
    client_id: clientId(),
    redirect_uri: encodeURI(window.location.origin + '/token'),
    response_type: 'code',
    approval_prompt: 'auto'
};
const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
const query = `${prefix}?${queryString}`;
function authenticate() {
    window.location.href = query;
}
function clientId() {
    return document.getElementsByName('STRAVA_CLIENT_ID')[0].getAttribute('content');
}
exports.default = authenticate;
