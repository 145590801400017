"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const flashMessagesReducer = (state = [], action) => {
    switch (action.type) {
        case 'SHOW_MESSAGE':
            return [...state, { text: action.text }];
        case 'DISCARD_MESSAGES':
            return [];
        default:
            return state;
    }
};
exports.default = flashMessagesReducer;
