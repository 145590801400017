"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Source = exports.Stage = void 0;
var Stage;
(function (Stage) {
    Stage[Stage["start"] = 0] = "start";
    Stage[Stage["show"] = 1] = "show";
    Stage[Stage["export"] = 2] = "export";
})(Stage || (Stage = {}));
exports.Stage = Stage;
var Source;
(function (Source) {
    Source[Source["file"] = 0] = "file";
    Source[Source["strava"] = 1] = "strava";
})(Source || (Source = {}));
exports.Source = Source;
