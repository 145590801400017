"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TCXNode = void 0;
const xml_wrappers_1 = require("xml-wrappers");
class TCXNode {
    constructor(node) {
        this.node = node;
    }
    children(name) {
        return xml_wrappers_1.children(this.node, name).map(node => new TCXNode(node));
    }
    firstChild(name) {
        const childNode = xml_wrappers_1.firstChild(this.node, name);
        if (!childNode) {
            throw new Error(`No ${name} child node found`);
        }
        return new TCXNode(childNode);
    }
    hasChild(name) {
        const childNode = xml_wrappers_1.firstChild(this.node, name);
        return !!childNode;
    }
    childContent(name, optionalDefault) {
        const childNode = xml_wrappers_1.firstChild(this.node, name);
        if (optionalDefault && (!childNode || !childNode.textContent)) {
            return optionalDefault;
        }
        if (!childNode) {
            if (optionalDefault) {
                return optionalDefault;
            }
            else {
                throw new Error(`No ${name} child node found`);
            }
        }
        if (!childNode.textContent) {
            if (optionalDefault) {
                return optionalDefault;
            }
            else {
                throw new Error(`No content found at ${name}`);
            }
        }
        return childNode.textContent;
    }
}
exports.TCXNode = TCXNode;
