"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Activity = void 0;
class Activity {
    constructor(sport, laps, source = '') {
        this.sport = sport;
        this.laps = laps;
        this.source = source;
    }
    static empty() {
        const emptyLaps = [{ trackpoints: [], totals: { time: 1, distance: 1, maxSpeed: 1 } }];
        return new Activity(Activity.DEFAULT_SPORT, emptyLaps);
    }
    get trackpoints() {
        return this.laps[0].trackpoints;
    }
    get totalTime() {
        return this.laps.reduce((total, lap) => (total + lap.totals.time), 0);
    }
    replaceTrackpoints(trackpoints) {
        this.laps[0].trackpoints = trackpoints;
    }
}
exports.Activity = Activity;
Activity.DEFAULT_SPORT = 'Running';
